import React, { ReactElement } from "react";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/react/types";

import CloseIcon from "../../public/static/icons/close.svg";
import visuallyHidden from "../../styles/visually-hidden";
import { iconHoverPath } from "../../styles/icon";
import Tooltip from "./tooltip";
import pxToRem from "../../styles/px-to-rem";
import variables from "../../styles/variables";

const button = css`
  ${iconHoverPath()};
  padding: ${pxToRem(12)};
  background: ${variables.colorWhite};
  border-radius: 50%;
`;

const layout = css`
  display: grid;
  justify-content: center;
  align-items: center;
`;

const closeIcon = css`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
`;

type Input = {
  tooltip?: string;
  tooltipAlignment?: TooltipAlignment;
  style?: SerializedStyles;
  onClick?: () => void;
};

const CircleCloseButton = ({
  tooltip = "Close",
  tooltipAlignment = "right",
  onClick,
  style,
}: Input): ReactElement => {
  return (
    <Tooltip placement={tooltipAlignment} overlay={<span>{tooltip}</span>}>
      <button css={[button, style]} onClick={onClick} type="button">
        <div css={layout}>
          <CloseIcon css={closeIcon} />
          <p css={visuallyHidden}>{tooltip}</p>
        </div>
      </button>
    </Tooltip>
  );
};

export default CircleCloseButton;
